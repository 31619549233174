var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"store-merchandise-list-page-box"},[_c('div',{staticClass:"product-list"},[_c('div',{staticClass:"sort-header"},[_c('div',{class:['item', !_vm.sortObj.price && !_vm.sortObj.weight ? 'active' : ''],on:{"click":_vm.sortProduct}},[_vm._v(" 综合排序 ")]),_c('div',{class:['item', _vm.sortObj.price ? 'active' : ''],on:{"click":function($event){return _vm.sortProduct('price')}}},[_vm._v(" 商品单价 "),_c('p',{staticClass:"icon"},[_c('i',{class:_vm.sortObj.price === 1
                ? 'el-icon-caret-top active'
                : 'el-icon-caret-top'}),_c('i',{class:_vm.sortObj.price === 2
                ? 'el-icon-caret-bottom active'
                : 'el-icon-caret-bottom'})])]),_c('div',{class:['item', _vm.sortObj.weight ? 'active' : ''],on:{"click":function($event){return _vm.sortProduct('weight')}}},[_vm._v(" 库存重量 "),_c('p',{staticClass:"icon"},[_c('i',{class:_vm.sortObj.weight === 1
                ? 'el-icon-caret-top active'
                : 'el-icon-caret-top'}),_c('i',{class:_vm.sortObj.weight === 2
                ? 'el-icon-caret-bottom active'
                : 'el-icon-caret-bottom'})])])]),_vm._l((_vm.respGoodsHallList),function(item,index){return _c('div',{key:index,staticClass:"list"},[_c('img',{staticClass:"product-image",attrs:{"src":item.goodsMainImgUrl.indexOf('http') >= 0
            ? item.goodsMainImgUrl
            : _vm.$fileUrl + item.goodsMainImgUrl,"alt":"产品图片"}}),_c('div',{staticClass:"content-box"},[_c('div',{staticClass:"product-content"},[_c('h3',{staticClass:"name elp-1"},[_vm._v(" "+_vm._s(item.goodsName)+" ")]),_c('span',{staticClass:"description"},[_vm._v(_vm._s(item.goodsTwoCategoryName))]),_c('el-tooltip',{staticClass:"item",attrs:{"effect":"dark","placement":"top"}},[_c('div',{attrs:{"slot":"content"},slot:"content"},[_vm._v(" 联系人："+_vm._s(item.listingPerson)),_c('br'),_vm._v("联系电话："+_vm._s(item.personPhone)+" ")]),_c('p',{staticClass:"contact-information"},[_c('img',{attrs:{"src":require("@/assets/icon/concat.png"),"alt":"ICON"}}),_vm._v(" 联系卖家 ")])])],1),_c('div',{staticClass:"model"},[_c('p',[_vm._v(" "+_vm._s(item.warehouseName)+"  |  "+_vm._s(item.placeProvince)+_vm._s(item.placeCity)+" ")]),_c('span',[_vm._v(_vm._s(item.goodsMaterialName)+"  "+_vm._s(item.goodsSpecName))])]),_c('div',{staticClass:"price-or-weight"},[_c('p',[_vm._v("¥"+_vm._s(item.unitPriceIncludingTax || 0)+"/吨")]),_c('span',[_vm._v("库存重量："+_vm._s(item.availableListingWeight || 0)+"吨")])]),_c('router-link',{staticClass:"view-details-button",attrs:{"to":{
            path: '/spotHall/spotHallDetail',
            query: { shopId: item.shopId, id: item.id },
          }}},[_vm._v(" 查看详情 ")])],1)])}),(_vm.respGoodsHallList.length === 0)?_c('NoData',{staticClass:"no-data-box",attrs:{"type":5}}):_vm._e()],2),(!_vm.pageType)?_c('Pagination',{attrs:{"get-data-list":_vm.getdata,"params":_vm.search,"total":_vm.total,"layout-option":'sizes,prev, pager, next'}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }