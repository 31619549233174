<template>
  <div class="store-merchandise-list-page-box">
    <div class="product-list">
      <div class="sort-header">
        <div
          :class="['item', !sortObj.price && !sortObj.weight ? 'active' : '']"
          @click="sortProduct"
        >
          综合排序
        </div>
        <div
          :class="['item', sortObj.price ? 'active' : '']"
          @click="sortProduct('price')"
        >
          商品单价
          <p class="icon">
            <i
              :class="
                sortObj.price === 1
                  ? 'el-icon-caret-top active'
                  : 'el-icon-caret-top'
              "
            />
            <i
              :class="
                sortObj.price === 2
                  ? 'el-icon-caret-bottom active'
                  : 'el-icon-caret-bottom'
              "
            />
          </p>
        </div>
        <div
          :class="['item', sortObj.weight ? 'active' : '']"
          @click="sortProduct('weight')"
        >
          库存重量
          <p class="icon">
            <i
              :class="
                sortObj.weight === 1
                  ? 'el-icon-caret-top active'
                  : 'el-icon-caret-top'
              "
            />
            <i
              :class="
                sortObj.weight === 2
                  ? 'el-icon-caret-bottom active'
                  : 'el-icon-caret-bottom'
              "
            />
          </p>
        </div>
      </div>
      <div v-for="(item, index) in respGoodsHallList" :key="index" class="list">
        <!-- 产品图片 -->
        <img
          class="product-image"
          :src="
            item.goodsMainImgUrl.indexOf('http') >= 0
              ? item.goodsMainImgUrl
              : $fileUrl + item.goodsMainImgUrl
          "
          alt="产品图片"
        >
        <div class="content-box">
          <!-- 产品详情 -->
          <div class="product-content">
            <h3 class="name elp-1">
              {{ item.goodsName }}
            </h3>
            <span class="description">{{ item.goodsTwoCategoryName }}</span>
            <el-tooltip class="item" effect="dark" placement="top">
              <div slot="content">
                联系人：{{ item.listingPerson }}<br>联系电话：{{
                  item.personPhone
                }}
              </div>
              <p class="contact-information">
                <img src="@/assets/icon/concat.png" alt="ICON">
                联系卖家
              </p>
            </el-tooltip>
          </div>
          <!-- 产品仓库及地方 -->
          <div class="model">
            <p>
              {{ item.warehouseName }}&nbsp;&nbsp;|&nbsp;&nbsp;{{
                item.placeProvince
              }}{{ item.placeCity }}
            </p>
            <span>{{ item.goodsMaterialName }}&nbsp;&nbsp;{{
              item.goodsSpecName
            }}</span>
          </div>
          <!-- 价格跟重量 -->
          <div class="price-or-weight">
            <p>¥{{ item.unitPriceIncludingTax || 0 }}/吨</p>
            <span>库存重量：{{ item.availableListingWeight || 0 }}吨</span>
          </div>
          <router-link
            class="view-details-button"
            :to="{
              path: '/spotHall/spotHallDetail',
              query: { shopId: item.shopId, id: item.id },
            }"
          >
            查看详情
          </router-link>
        </div>
      </div>
      <NoData
        v-if="respGoodsHallList.length === 0"
        class="no-data-box"
        :type="5"
      />
    </div>
    <!-- 分页  查询的时候才会有的  -->
    <Pagination
      v-if="!pageType"
      :get-data-list="getdata"
      :params="search"
      :total="total"
      :layout-option="'sizes,prev, pager, next'"
    />
  </div>
</template>

<script>
import { pageSpotHallGoodsShop } from '@/http/storeManagement'
import Pagination from '@/components/Pagination.vue'
import NoData from '@/components/NoData.vue'
export default {
  components: { Pagination, NoData },
  props: {
    pageType: {
      type: Boolean,
      default: false
    },
    keyword: {
      type: String,
      default: ''
    },
    cmpCode: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      search: {
        pageSize: 10,
        pageNum: 1,
        orderRuleData: {
          orderColumnName: '',
          orderRule: ''
        }
      },
      sortObj: {
        price: null,
        weight: null
      },
      respGoodsHallList: [],
      total: 0
    }
  },
  watch: {
    keyword: {
      handler(newVal) {
        this.search = {
          keyword: newVal || '',
          pageSize: this.pageType ? 15 : 10,
          pageNum: 1,
          cmpCode: this.cmpCode,
          orderRuleData: {
            orderColumnName: '',
            orderRule: ''
          }
        }
        this.sortObj = {
          price: null,
          weight: null
        }
        if (this.cmpCode) this.getdata()
      },
      deep: true,
      immediate: true
    },
    cmpCode: {
      handler(newVal) {
        this.search = {
          keyword: this.keyword || '',
          pageSize: this.pageType ? 15 : 10,
          pageNum: 1,
          cmpCode: newVal,
          orderRuleData: {
            orderColumnName: '',
            orderRule: ''
          }
        }
        this.sortObj = {
          price: null,
          weight: null
        }
        if (newVal) this.getdata()
      },
      deep: true,
      immediate: true
    }
  },
  methods: {
    // 当点击更多的时候调用这个接口
    getdata() {
      this.search.cmpCode = this.cmpCode || ''
      pageSpotHallGoodsShop(this.search, (res) => {
        this.respGoodsHallList = [...res.data.records]
        this.total = res.data.total || 0
      })
    },
    // 点击排序按钮
    sortProduct(type) {
      switch (type) {
        case 'price':
          this.sortObj.weight = null
          this.search.orderRuleData.orderColumnName = 'unitPriceIncludingTax'
          break
        case 'weight':
          this.sortObj.price = null
          this.search.orderRuleData.orderColumnName = 'availableListingWeight'
          break
        default:
          this.sortObj = {
            price: null,
            weight: null
          }
          this.search.orderRuleData.orderColumnName = ''
          break
      }
      switch (this.sortObj[type]) {
        case 1:
          this.sortObj[type] = 2
          this.search.orderRuleData.orderRule = 'desc'
          break
        case 2:
          this.sortObj[type] = 1
          this.search.orderRuleData.orderRule = 'asc'
          break
        default:
          this.sortObj[type] = 1
          this.search.orderRuleData.orderRule = 'asc'
          break
      }
      this.getdata()
    }
  }
}
</script>

<style lang="scss" scoped>
.store-merchandise-list-page-box {
  .list {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 80px;
    padding: 16px 24px;
    .content-box {
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex: 1;
    }
    .view-details-button {
      display: inline-block;
      width: 103px;
      height: 38px;
      border: 1px solid #e7e7e7;
      background-color: #fff;
      text-align: center;
      line-height: 38px;
      color: #d31e0e;
      font-size: 16px;
      font-weight: 400;
      transition: all 0.5s;
    }
    .view-details-button:hover {
      color: #fff;
      background-color: #d31e0e;
      border-color: #d31e0e;
    }
    .price-or-weight {
      display: flex;
      align-items: flex-start;
      justify-content: center;
      flex-direction: column;
      flex: 0 0 144px;
      p {
        color: #d31e0e;
        font-size: 22px;
        font-weight: 500;
      }
      span {
        color: #999999;
        font-size: 14px;
        font-weight: 400;
        margin-top: 4px;
      }
    }
    .model {
      display: flex;
      align-items: flex-start;
      justify-content: center;
      flex-direction: column;
      flex: 0 0 220px;
      p {
        color: #333333;
        font-size: 16px;
        font-weight: 400;
      }
      span {
        margin-top: 8px;
        color: #666666;
        font-size: 14px;
        font-weight: 400;
      }
    }
    .product-content {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      flex-direction: column;
      height: 80px;
      flex: 0 0 296px;
      .contact-information {
        display: flex;
        align-items: center;
        background-color: #fff;
        height: 20px;
        color: #f89705;
        font-size: 14px;
        background-color: rgb(253, 246, 239);
        cursor: pointer;
        img {
          height: 16px;
          width: 16px;
        }
      }
      .description {
        display: inline-block;
        background-color: rgb(251, 237, 236);
        color: #d31e0e;
        font-size: 14px;
        padding: 0 8px;
        height: 24px;
        line-height: 24px;
      }
      .name {
        color: #333333;
        font-size: 16px;
        font-weight: 500;
      }
    }
    .product-image {
      flex: 0 0 80px;
      max-width: 80px;
      height: 80px;
      margin-right: 16px;
    }
  }
  .product-list {
    border: 1px solid #e7e7e7;
    border-radius: 2px;
    width: $centerPlateWidth;
    margin: 16px auto;
    .no-data-box {
      padding: 120px 0;
    }
    .sort-header {
      display: flex;
      height: 44px;
      background-color: #f9f9f9;
      border-bottom: 1px solid #e7e7e7;
      box-sizing: border-box;
      .item {
        display: flex;
        height: 100%;
        align-items: center;
        justify-content: center;
        padding: 0 16px;
        color: #666666;
        font-size: 14px;
        cursor: pointer;
        .icon {
          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: column;
          i {
            font-size: 12px;
            margin-left: 4px;
            color: #d5d5d5;
          }
          i:first-of-type {
            transform: translateY(2px);
          }
          i:last-of-type {
            transform: translateY(-2px);
          }
          .active {
            color: #999999;
          }
        }
      }
      .active {
        position: relative;
        color: #d31e0e;
        background-color: #fff;
      }
      .active:after {
        content: " ";
        position: absolute;
        bottom: 0;
        left: 14px;
        width: calc(100% - 28px);
        background-color: #d31e0e;
        height: 2px;
      }
    }
  }
}
</style>
