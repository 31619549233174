<template>
  <div class="shop-tabs">
    <div class="tabs-auto">
      <span v-for="(item) in list" :key="item.path" :class="{'active' : item.path === pathActive.split('?')[0]}" @click="changePath(item.path)"><p v-if="item.show">{{ item.name }}</p></span>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    tabActivePath: {
      default: '/storeManagement/storeHomepage',
      type: String
    }
  },
  data() {
    return {
      list: [
        { path: '/storeManagement/storeHomepage', name: '店铺首页', show: true },
        { path: '/storeManagement/ourStoreGoods', name: '全部商品', show: true },
        { path: '/processingServices/processServicesHome', name: '加工服务', show: true }
      ],
      pathActive: ''
    }
  },
  watch: {
    tabActivePath: {
      handler(val) {
        this.pathActive = val
      },
      immediate: true
    }
  },
  mounted() {
    this.list[2].show = this.$route.query.id && this.$route.query.id.length > 0
  },
  methods: {
    changePath(path) {
      this.pathActive = path
      this.$emit('changeShopPath', path)
    }
  }
}
</script>

<style lang="scss" scoped>
.shop-tabs {
  background-color: #D31E0E;
  color: #fff;
  height: 36px;
  .tabs-auto {
    width: 1200px;
    margin: auto;
  }
  span {
    font-size: 14px;
    display: inline-block;
    height: 100%;
    width: 104px;
    line-height: 36px;
    text-align: center;
    cursor: pointer;
  }
  .active {
    background-color: #BF1C0E;
  }
}
</style>
